.rstm-toggle-icon {
    display: inline-block;
  }
  .rstm-toggle-icon-symbol {
    width: 2rem;
    height: 1rem;
    text-align: center;
    line-height: 2rem;
  }
  
  .rstm-tree-item-group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #ccc;
    text-align: left;
    width: 100%;
  }
  
  .rstm-tree-item {
    padding: 0.2rem 1rem;
    cursor: pointer;
    color: #333;
    background: none;
    box-shadow: none;
    z-index: unset;
    position: relative;
  }
  .rstm-tree-item--active {
    color: white;
    background: #179ed3;
    border-bottom: none;
  }
  .rstm-tree-item--focused {
    box-shadow: 0 0 5px 0 #222;
    z-index: 999;
  }
  
  .rstm-search {
    padding: 1rem 1.5rem;
    border: none;
    width: 100%;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px solid black;
  }
  
  